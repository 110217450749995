// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  development: true,
  staging: false,
  local: false,
  backendUrl:"https://imka7oeyu8.execute-api.ap-south-1.amazonaws.com/qa/",
  centralPortalUrl: 'https://qa.portal.99ticks.com/',
  domain : '.99ticks.com',
  env: "qa"
};
